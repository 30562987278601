import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';
import { mapGetters } from 'vuex';

export const DEFAULT_PROMOTED_USECASES = [
  '5fb5114f0e4fb2001328a0b7', // Newsletter signup
  '607d6cff2cdacf001d08a858', // Contact Form
  '5fb516f60e4fb2001328a0cc', // Marketing Research Survey
  '5ffc54cab2317100121de450', // Announcement
];

export const DEFAULT_THEME_NAME = 'Clean';

export default {
  data: () => ({
    showCustomThemesTitle: true,
    customThemesLoading: true,
  }),
  computed: {
    ...mapGetters(['wizardPreferences']),
    preferredTheme() {
      return (
        this.customThemes?.[0]?.sourceTheme?.name ||
        this.wizardPreferences?.style ||
        DEFAULT_THEME_NAME
      );
    },
    defaultTheme() {
      return this.baseThemes?.find?.(({ name }) => name === DEFAULT_THEME_NAME);
    },
    preferredBaseThemeId() {
      if (this.wizardPreferences?.customThemeId) return this.wizardPreferences?.customThemeId;
      return this.defaultTheme?._id;
    },
    preferredThemeId() {
      return this.customThemes?.[0]?.sourceTheme?._id || this.preferredBaseThemeId;
    },
    preferredThemeUseCases() {
      return this.recommendedUseCases.filter((useCase) =>
        useCase.templates.some(
          (template) => this.preferredThemeId === template?.template?.themeKit?.id,
        ),
      );
    },
    promotedUsesCases() {
      const promoted = this.preferredThemeUseCases.filter((useCase) => useCase.promoted);
      return promoted.filter(({ templates }) =>
        templates.some(({ template }) => this.preferredThemeId === template?.themeKit?.id),
      );
    },
  },
  methods: {
    async loadCustomThemes() {
      this.customThemesLoading = true;
      const { data } = await this.$apollo.query({
        query: GET_CUSTOM_THEMES,
      });
      this.baseThemes = data.themes.base;
      this.customThemes = (data.themes.custom || []).reverse();
      this.customThemesLoading = false;
    },
    async populateRecommendedUseCases() {
      this.recommendedUseCases = await this.fetchRecommendedUseCases(
        this.getRecommendationParams(),
      );
      if (this.promotedUsesCases.length < 2) {
        this.recommendedUseCases = await this.fetchRecommendedUseCases(
          this.getRecommendationParams(true),
        );
      }
    },

    getRecommendationParams(promoteDefault = false) {
      const recommendationParams = {};
      Object.assign(recommendationParams, this.wizardPreferences);

      if (!this.wizardPreferences?.useCases?.length || promoteDefault) {
        Object.assign(recommendationParams, {
          promotedUseCases: DEFAULT_PROMOTED_USECASES,
        });
      } else {
        const promotedKey =
          this.wizardPreferences.useCases?.find((useCase) => useCase.includes('buildList')) ||
          'buildList.email.yes';
        Object.assign(recommendationParams, { promotedKey });
      }

      recommendationParams.style = this.preferredTheme;

      return recommendationParams;
    },
  },
};
