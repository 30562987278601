<template lang="pug">
mixin fixTactic
  .template-item-preview.fix-tactic-preview(@click="$emit('selectFixTactic')")
    img(:src="require(`@/assets/admin/img/${fixTactic.image}`)")
  .template-item-content
    .template-item-title
      span(@click="$emit('selectFixTactic')") {{ fixTactic.name[$i18n.locale] }}

.col-4.recommendation-tactic-card(v-if="template || hasFixTactic")
  template(v-if="hasFixTactic")
    +fixTactic
  template(v-else)
    .template-item-preview
      transition(name="fade")
        template-frame.cursor-pointer(
          @observable="$emit('observable', $event)"
          allowSsr
          :dimensions="dimensions"
          @inited="$emit('inited')"
          :template="template"
          @contentLoaded="contentLoadedWrapper"
          @click.native="showTemplatePreview()"
          :themeKit="recommendedCustomTheme ? recommendedCustomTheme.themeKit : undefined"
        )
          template(#fallback)
            template-box.cursor-pointer(:template="template" @click.native="showTemplatePreview()")
    .template-item-content
      .template-item-title
        span(@click="showTemplatePreview()") {{ useCase.name[getLocale] }}
</template>

<script>
  /*
  [Dzsi] IMPROVEMENT: it would be worth combing with the wizard tactic card to eliminate duplications
  (packages/om-admin/src/components/Tactics/RecommendationCard.vue)
  */
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import customThemeRecommendationMixin, {
    DEFAULT_THEME_NAME,
  } from '@/mixins/customThemeRecommendation';
  import { mapGetters } from 'vuex';
  import TemplateBox from '@/components/Template/Themes/TemplateBox';
  import TemplateFrame from '@/components/Template/TemplateFrame';
  import wizardSharedMixin from '@/views/Wizard/shared';

  export default {
    components: { TemplateBox, TemplateFrame },
    mixins: [wizardSharedMixin, previewParentMixin, ssrMixin, customThemeRecommendationMixin],
    props: {
      useCase: {
        type: Object,
        default: () => ({}),
      },
      dimensions: {
        type: Object,
        default: () => ({
          width: null,
          height: null,
        }),
      },
      customThemes: {
        type: Array,
        default: () => [],
      },
      baseThemes: {
        type: Array,
        default: () => [],
      },
      showcase: {
        type: Boolean,
        default: false,
      },
      fixTactic: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      template: null,
    }),
    computed: {
      ...mapGetters(['wizardPreferences', 'getLocale']),
      wizardPreferencesColor() {
        return this.wizardPreferences.color;
      },
      preferredTheme() {
        const sourceTheme = (this.baseThemes || []).find(({ _id }) => {
          return _id === this.customThemes?.[0]?.sourceTheme;
        });
        return (
          this.customThemes?.[0]?.sourceTheme?.name ||
          sourceTheme?.name ||
          this.wizardPreferences?.style ||
          DEFAULT_THEME_NAME
        );
      },
      preferredBaseThemeId() {
        return this.wizardPreferences?.customThemeId || this.defaultTheme?._id;
      },
      preferredThemeId() {
        return (
          this.customThemes?.[0]?.sourceTheme?._id ||
          this.customThemes?.[0]?.sourceTheme ||
          this.preferredBaseThemeId
        );
      },
      recommendedCustomTheme() {
        const fallbackTheme =
          this.baseThemes?.find(({ name }) => name === this.useCaseTemplatesTheme) ||
          DEFAULT_THEME_NAME;
        const customTheme = (this.customThemes || []).find(({ sourceTheme }) => {
          return sourceTheme.name === this.preferredTheme || sourceTheme === this.preferredThemeId;
        });
        return customTheme || fallbackTheme;
      },
      hasFixTactic() {
        return !!this.fixTactic?.name?.en;
      },
      useCaseTemplatesTheme() {
        return this.useCases?.[0]?.templates?.[0]?.theme;
      },
    },
    created() {
      if (this.hasFixTactic) return;
      this.template = this.getUseCaseRecommendTemplate();
    },
    methods: {
      getUseCaseRecommendTemplate() {
        const templates = this.showcase
          ? this.useCase.templates
          : this.useCase.templates.filter((template) => {
              return template?.template?.themeKit?.name === this.preferredTheme;
            });
        const interstitial = templates.find(({ type }) =>
          ['fullscreen', 'interstitial'].includes(type),
        );
        const popup = interstitial || templates.find(({ type }) => type === 'popup');
        const sidemessage = popup || templates.find(({ type }) => type === 'sidebar');
        const nanobar = sidemessage || templates.find(({ type }) => type === 'nanobar');
        const template = nanobar || templates.find(({ type }) => type === 'embedded');
        return template || templates[0];
      },
      showTemplatePreview() {
        const hasCustomTheme = !!this.recommendedCustomTheme;
        this.$modal.show('template-preview', {
          templateId: this.template._id,
          color: this.recommendedCustomTheme ? undefined : this.wizardPreferencesColor,
          isWizard: this.recommendedCustomTheme ? undefined : true,
          themeKit: this.recommendedCustomTheme?.themeKit,
          heapTracking: hasCustomTheme
            ? { name: 'template-recommendation-selection-wizard-custom-theme' }
            : {
                name: 'template-recommendation-selection-wizard',
                data: {
                  useCaseName: this.useCase.name[this.getLocale],
                  color: this.wizardPreferences.color,
                  coupon: this.wizardPreferences.coupon,
                  goal: this.wizardPreferences.goal,
                  list: this.wizardPreferences.list,
                  theme: this.template.theme,
                },
              },
        });
      },
      async goToSimilarTemplatesPage() {
        await this.updateWizardPreferences({ useCase: this.useCase._id });
        this.$router.push({
          name: 'similar_templates',
          query: {
            id: this.useCase._id,
            color: this.wizardPreferencesColor,
          },
        });
      },
      contentLoadedWrapper(event) {
        this.onContentLoaded(event);
        this.$emit('contentLoaded');
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .recommendation-tactics
    .recommendation-tactic-card
      cursor: pointer

      .fix-tactic-preview
        position: relative
        display: flex
        align-items: center
        justify-content: center
        border-radius: 0.25rem
        border: 1px solid #E3E8ED
        aspect-ratio: 157 / 89

        img
          height: 100%

      .template-item
        &-content
          padding: 10px 0
        &-title
          font-size: 0.9rem
          &:hover
            color: $om-orange-500
</style>
